const links = [
  {
    id: 0,
    text: "home",
    url: "#header-section",
  },
  {
    id: 1,
    text: "about",
    url: "#about-section",
  },
  {
    id: 2,
    text: "projects",
    url: "#projects-section",
  },
  {
    id: 3,
    text: "contact",
    url: "#contact-section",
  },
];

export default links;